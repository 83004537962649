import React from "react";
import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { RollbackOutlined } from "@ant-design/icons";
import { Link } from "gatsby";
import { Layout } from "antd";
import Revenga1 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta1.jpg";
import Revenga2 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta2.jpg";
import Revenga3 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta3.jpg";
import Revenga4 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta4.jpg";
import Revenga5 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta5.jpg";
import Revenga6 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta6.jpg";
import Revenga7 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta7.jpg";
import Revenga8 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta8.jpg";
import Revenga9 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta9.jpg";
import RevengaPre1 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta-PreAplicacion1.jpg";
import RevengaPre2 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta-PreAplicacion2.jpg";
import RevengaPre3 from "src/components/referencias/images/cubiertas/sede-grupo-revenga/climatecoating-thermoActive-cubierta-PreAplicacion3.jpg";

import { ThermoActiveImgAndLinks } from "src/components/referencias/cubiertas/ThermoActive";

import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

import { Image } from "antd";
import { List, Card } from "antd";
const { Header, Content } = Layout;

const data = [
  {
    src: Revenga1,
  },
  {
    src: Revenga2,
  },
  {
    src: Revenga3,
  },
  {
    src: Revenga4,
  },
  {
    src: Revenga5,
  },
  {
    src: Revenga6,
  },
  {
    src: Revenga7,
  },
  {
    src: Revenga8,
  },
  {
    src: Revenga9,
  },
  {
    src: RevengaPre1,
  },
  {
    src: RevengaPre2,
  },
  {
    src: RevengaPre3,
  },
];

const SedeGrupoRevenga = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      showPageTitle: false,
      pageTitle: "ThermoActive: Sede del Grupo Revenga en Madrid",
      pageName: "Referencia ThermoActive",
      pageDescription:
        "ClimateCoating ThermoActive: Usado revistiendo la cubierta sobre tela asfáltica",
    }}
  >
    <Content className="thermoActive referencia">
      <div className="linkBack referencia">
        <Link to="/referencias/cubiertas/">
          <RollbackOutlined />{" "}
          <span className="colored linkText">Ir a Referencias Cubiertas</span>
        </Link>
      </div>

      <div className="marginTop50">
        <ThermoActiveImgAndLinks />
        <h1 className="pageTitle referencia">
          <b className="thermoActive">ClimateCoating ThermoActive: </b>
          <span className="secondText">
            Aplicación sobre tela asfáltica en la cubierta del Grupo Constructor
            Revenga
          </span>
        </h1>
        <p>
          Cómo puede ver en detalle en la página de
          <Link to={"/productos/thermoActive/"} className="grey bold">
            ThermoActive
          </Link>
          , además de sus características reflectantes, dispone de una
          flexibilidad y capacidad de adherencia que permite su aplicación
          óptima sobre tela asfáltica.
        </p>
        <p>
          Con el paso del tiempo la tela asfáltica sufre una degradación
          constante,{" "}
          <strong className="grey">
            que puede poner en riesgo sus propiedades impermeabilizantes
          </strong>
          , si a ello le sumamos las temperaturas que alcanza en el verano
          madrileño, valorar una solución como la aplicación de ClimateCoating
          ThermoActive es la mejor opción.
        </p>
        <br />
        <br />
      </div>
      <div className="clearer"></div>
      <Image.PreviewGroup preview={{}}>
        <List
          grid={{ gutter: 16, column: 3 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Image src={item.src} />
            </List.Item>
          )}
        />
      </Image.PreviewGroup>
      <br />
      <br />
    </Content>
  </LayoutGeneral>
);

export default SedeGrupoRevenga;
